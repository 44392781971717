<template>
    <div>
        <ViewTitle title="轄下佣金查詢" />
        <UnderlingFilter
            :isLoading="isLoadingTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
            @show-data-table="showDataTable"
        />
    </div>
    <SearchCondition
        :dataList="searchConditionList"
        :isShowTable="true"
        :isHasListData="listData.length > 0"
        :isLoading="isLoadingTable"
    />
    <UnderlingTable
        :listData="listData"
        :commissionList="commissionList"
        :filterLog="filterLog"
        :isLoading="isLoadingTable"
        :headerData="headerData"
    />
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue'
import UnderlingFilter from '@/containers/commission/UnderlingFilter.vue'
import UnderlingTable from '@/containers/commission/UnderlingTable.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import { getCommissionOrgHistoryAPI } from '@/assets/javascripts/api'

export default {
    name: 'CommissionUnderling',
    components: {
        ViewTitle,
        UnderlingFilter,
        UnderlingTable,
        SearchCondition
    },
    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) {
                this.isLoadingTable = false
                return
            }
            try {
                this.isLoadingTable = true
                this.$showLoading()

                /* Get Table Data */
                let res = await this.$getResponse(
                    this.filterPayload,
                    { apiAlias: 'list', apiName: '此報表' },
                    getCommissionOrgHistoryAPI
                )
                this.apiDataProcess(res)
            } catch (error) {
                this.$showErrorMessage(
                    '查詢時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.isLoadingTable = false
                this.$hideLoading()
            }
        },
        apiDataProcess: function (response) {
            this.listData = response.data?.member
            this.commissionList = response.data?.commission
            this.headerData = response.data?.header
        }
    },
    computed: {
        searchConditionList: function () {
            return [
                {
                    title: '工作月日期區間',
                    value: `${this.headerData.startDate} 至 ${this.headerData.endDate}`
                }
            ]
        }
    },
    data() {
        return {
            isLoadingTable: false,
            filterPayload: {},
            filterLog: {},
            headerData: {},
            listData: [],
            commissionList: {}
        }
    }
}
</script>

<style lang="scss" scoped></style>
